import './App.css';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Footer from './Component/Footer';
import Header from './Component/Header';
import Home from './Component/Home';
import About from './Component/About';
import Admission from './Component/Admission';
import Universities from './Component/Universities'

function App() {
  return (
    <>
      
      <Router>
        <Header />
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/admission' element={<Admission />} />
            <Route path='/universities' element={<Universities />} />
          </Routes> 
        <Footer />
      </Router>
    </>
  );
}

export default App;
