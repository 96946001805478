import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Testimonial = () =>
{
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          600: {
            items: 1,
          },
          700: {
            items: 1,
          },
          1000: {
            items: 1,
          }
        },
      };

    return(
        <>
            <section className="testimonial_sec">
        <div className="container">
          <div className="testi_area_wrap">
            <div className="row">
              <div className="col-lg-7 wow fadeInUp">
                <div className="heading_box title_ribban">
                  <div className="testi_left">
                    <h2>Testimonials - </h2>
                    <h4>Reviews From Happy Students</h4>
                    <p>The benefits that international MBBS students receive include better access to laboratories, a chance to study at major universities, and a smaller ratio of students per teacher.</p>
                    <p>In addition to working in the host country, candidates can eventually settle there. Depending on the student's needs, students can choose between <b>MBBS course in Russia, MBBS course in Kazakhstan, MBBS course in Bangladesh, MBBS course in Armenia, and MBBS course in Georgia</b> for their study abroad program.</p>
                    <a className="testi_more comman_btn" href="https://www.doctorbano.com/universities.php">Find list of universities</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 md_res_mar_top_30 wow fadeInDown">
                <div className="testi_area">
                <OwlCarousel {...options} items={3}
                    className="owl-theme"
                    loop
                    nav
                    margin={8} >
                    <div >
                      <div className="client_content">
                          <div className="testi_author">
                          <div className="testi_img">
                              <div className="testi_img_inner">
                              <img src="images/testi_img1.jpg" alt="top Education Consultant in Delhi" title="top Education Consultant in Delhi" />
                              </div>
                          </div>
                          <div className="testi_name">
                              <div className="testi_name_inner">
                              <h4>Aman Fatima</h4>
                              {/* <p>Manager</p> */}
                              </div>
                          </div>
                          </div>
                          <p>Study MBBS aborad was like impossible to us, I and my father were just doubtful but the best guidance by doctorbano fulfilled our dream.</p>
                      </div>
                    </div>
                    <div>2</div>
                    <div>3</div>
                    <div>4</div>
                    <div>5</div>
                    <div>6</div>
                    <div>7</div>
                </OwlCarousel>
                </div>
                
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </section>
        </>
    )
}
export default Testimonial