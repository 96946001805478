import React from 'react'

const Footer = () => {
  return (
    <>
      <section className="footer_sec">
        <div className="container footer_con">
          {/* cv_animation start */}
          <div className="cv_animation">
            <div className="c_shape1 c_shape_comman">
              <img src="images/c_shape1.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape2 c_shape_comman">
              <img src="images/c_shape2.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape3 c_shape_comman">
              <img src="images/c_shape3.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape4 c_shape_comman">
              <img src="images/c_shape4.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape5 c_shape_comman">
              <img src="images/c_shape5.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape6 c_shape_comman">
              <img src="images/c_shape6.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape7 c_shape_comman">
              <img src="images/c_shape7.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape8 c_shape_comman">
              <img src="images/c_shape8.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape9 c_shape_comman">
              <img src="images/c_shape9.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape10 c_shape_comman">
              <img src="images/c_shape10.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            <div className="c_shape11 c_shape_comman">
              <img src="images/c_shape11.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
            </div>
            {/* <div class="c_shape12 c_shape_comman">
          <img src="images/c_shape12.png" alt="">
        </div> */}
          </div>
          {/* cv_animation end */}
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_box">
                <h2 className="ft_heading">Study MBBS in Russia 2022</h2>
                <div className="ft_contact">
                  <div className="ft_logo">
                    <img src="images/logo.png" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" />
                  </div>
                  <br />
                  <p>Doctorbano.com, an Essqube Enterprises LLP initiative, established on March 9, 2017, has stepped into this space to help students and their parents realize their dreams of pursuing medical education that has an international flavour and exposure. </p>
                  <ul className>
                    <li className="li_social"> 
                      <a href="https://www.facebook.com/essqube" target="_blank"><i className="fa fa-facebook" aria-hidden="true" /></a> 
                      <a href="https://twitter.com/essqube" target="_blank"><i className="fa fa-twitter" aria-hidden="true" /></a> 
                      {/* <a href="" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>  */}
                      <a href="https://www.linkedin.com/company/essqube-enterprises-llp/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true" /></a> 
                      <a href="https://www.instagram.com/essqube/" target="_blank"><i className="fa fa-instagram" aria-hidden="true" /></a> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_box">
                <h2 className="ft_heading">Important Links</h2>
                <div className="ft_links">
                  <ul className>
                    <li><a href="https://www.doctorbano.com/about.php">About Us</a></li>
                    <li><a href="https://www.doctorbano.com/admission.php">Admission</a></li>
                    <li><a href="https://www.doctorbano.com/countries.php">Universities</a></li>
                    <li><a href="https://www.doctorbano.com/blog.php">Blog</a></li>
                    <li><a href="https://www.doctorbano.com/privacy-policy.php">Privacy Policy</a></li>
                    <li><a href="https://www.doctorbano.com/terms-conditions.php">Terms &amp; Conditions</a></li>
                    <li><a href="#">Sitemap</a></li>
                    <li><a href="tel:8447730303">Support on Call</a></li>
                    <li><a target="_blank" href="https://wa.me/8447730303">Chat</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_box">
                <h2 className="ft_heading">Where to Study</h2>
                <div className="ft_links">
                  <ul className>
                    <li><a href="https://www.doctorbano.com/mbbs-in-russia-for-indian-students.php">MBBS in Russia</a></li>
                    <li><a href="https://www.doctorbano.com/mbbs-in-kazakhstan-for-indian-students.php">MBBS in Kazakhstan</a></li>
                    <li><a href="https://www.doctorbano.com/mbbs-in-georgia-for-indian-students.php">MBBS in Georgia</a></li>
                    <li><a href="https://www.doctorbano.com/mbbs-in-bangladesh-for-indian-students.php">MBBS in Bangladesh</a></li>
                    <li><a href="https://www.doctorbano.com/mbbs-in-armenia-for-indian-students.php">MBBS in Armenia</a></li>
                    <li><a href="https://www.doctorbano.com/mbbs-in-kyrgyzstan-for-indian-students.php">MBBS in Kyrgyzstan</a></li>
                    <li><a href="https://www.doctorbano.com/mbbs-in-china-for-indian-students.php">MBBS in China</a></li>
                    <li><a href="https://www.doctorbano.com/mbbs-in-philippines-for-indian-students.php">MBBS in Philippines</a></li>
                    <li><a href="https://www.doctorbano.com/mbbs-in-ukraine-for-indian-students.php">MBBS in Ukraine</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_box">
                <h2 className="ft_heading">Consultancy Services</h2>
                <div className="ft_contact">
                  <p><b>Doctorbano.com<br />
                      Essqube Enterprises LLP</b><br />
                    A-23, Connaught Place,<br /> New Delhi – 110001</p>
                  <p><a href="tel:8447730303">+91-8447730303</a> <br />
                    <a href="tel:8447630303">+91-8447630303</a> </p>
                  <p>
                    <a href="mailto:essqubellp@gmail.com">essqubellp@gmail.com</a> <br />
                    <a href="mailto:info@doctorbano.com">info@doctorbano.com</a>
                    <a href="mailto:info@essqubellp.com">info@essqubellp.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer