import React from 'react'
import WhyChoose from './WhyChoose'

const About = () => {
  return (
    <>
    {/* banner_new_sec */}
    <section className="banner_new_sec country_banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 ban_content_main d-flex align-items-center wow fadeInLeft">
                <div className="ban_content">
                  <h1 className="wow fadeInUp"><span>Best MBBS abroad consultants in Delhi</span></h1>
                  <h4 className="wow fadeInDown">Direct Admission in MBBS Abroad</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ban_img_main wow fadeInLeft">
                <div className="ban_img_bx">
                  <img src="images/study-mbbs-abroad-education-consultants-in-delhi.png" alt="Best MBBS abroad consultants in delhi" title="Best MBBS abroad consultants in delhi" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
        <section className="about_contact_sec">
          <div className="container">
            <div className="about_home_area">
              <div className="row">
                <div className="col-lg-6 col-md-12 about_image_main md_res_mar_top_30 wow fadeInUp">
                  <div className="home_abt_img ">
                    <div className="img_radius">
                      <img src="images/about_us_page_img1.jpg" alt="Best MBBS abroad consultants 2022" title="Best MBBS abroad consultants 2022" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 about_content_main d-flex align-items-center wow fadeInDown md_res_mar_top_30">
                  <div className="about-content">
                    {/* <h3>WHO AM I</h3> */}
                    <h2 className="sub_heading title_ribban">Welcome to Doctorbano</h2>
                    <p>Engraving the name as leading <b>best MBBS abroad consultants in Delhi</b>. Pursuing a medical course in India can be an uphill task with the students having to first deal with the tough entrance exams and then the very high cost of the education (approximately 75 lacs). Even if one manages to clear the entrance, there is not guarantee that one will be able to secure the program of their choice. This is where studying abroad in some of the medical universities can be a viable option for many aspirants.</p>
                    <p>This is to ensure one’s future employability after one completes the program. Some of the most popular destinations for studying medicine abroad are: <b>MBBS in Russia, MBBS in Kazakhstan, MBBS in Georgia</b>. Even though getting admissions into these universities is not very difficult, there is still some detailed paperwork and process involved which if not followed properly can be a dampener for your dreams.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* other_feature_sec */}
        {/*?php include ("include/other_feature.php") ?*/}
        {/*  */}
        <section className="university_sec curve_bg_style bg_color_orange">
          <div className="bg_overlay" />
          <div className="container">
            {/* <div class="row">
        <div class="col-lg-6 wow flipInX">
          <h3 class="sub_heading title_ribban">Placement in Universities</h3>
        </div>
      </div> */}
            <div className="comman_top-">
              <div className="row">
                <div className="col-lg-8 top_uni_left">
                  <div>
                    <p>Doctorbano.com, an Essqube Enterprises LLP initiative, established on March 9, 2017, has stepped into <b>educational consultancy services in Delhi</b> to help students and their parents to pursue medical education that has an international flavour and exposure. Doctorbano one of the best MBBS abroad consultants in delhi has developed reciprocal relationships with some of the best medical universities like <b>MBBS in Russia, MBBS in Kazakhstan, MBBS in Georgia, MBBS in Bangladesh, MBBS in Armenia, MBBS in Ukraine, MBBS in China</b> and also around the world that allows them to easily place the aspiring Indian students there.</p>
                    <p>The courteous and knowledgeable Doctorbano personnel ensure that they are available to the student and his or her family at the every step of the admission process. The relationship does not end with the student gaining <b>direct admission in MBBS in Russia</b> but continues even after that where Doctorbano's team continues to help the students and the parents in myriad ways.</p>
                  </div>
                </div>
                <div className="col-lg-4 top_uni_right wow fadeInRight">
                  <div className="top_uni_img">
                    <img src="images/uni_student_img.png" alt="direct admission in MBBS" title="direct admission in MBBS" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
        <section className="about_contact_sec mt-5">
          <div className="container">
            <div className="about_home_area">
              <div className="row">
                <div className="col-lg-6 col-md-12 about_image_main md_res_mar_top_30 wow fadeInUp">
                  <div className="home_abt_img ">
                    <div className="img_radius">
                      <img src="images/best-mbbs-abroad-consultants-in-delhi-in-2022-2023.png" alt="best mbbs abroad consultants in delhi 2022-2023" title="best mbbs abroad consultants in delhi 2022-2023" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 about_content_main d-flex align-items-center wow fadeInDown md_res_mar_top_30">
                  <div className="about-content">
                    {/* <h3>WHO AM I</h3> */}
                    <h3 className="sub_heading title_ribban">Expert MBBS abroad consultants admission counsellors</h3>
                    <p>Our trained admission counsellors and personnel have the expertise on the entire end-to-end process required to obtain <b>direct admissions for medical programs in Russia</b>, Armenia, Bangladesh, Kazakhstan:</p>
                    <ul className="list_styled abt_list">
                      <b><li>MBBS in Russia</li>
                        <li>MBBS in Georgia</li>
                        <li>MBBS in Armenia</li>
                        <li>MBBS in Kazakhstan</li>
                        <li>MBBS in Bangladesh</li>
                        <li>MBBS in Kyrgyzstan</li>
                        <li>MBBS in China</li>
                        <li>MBBS in Ukraine</li>
                        <li>MBBS In Philippines</li></b>
                    </ul>
                    <p>The reason that these medical universities have emerged as a choice destinations for pursuing <b>top medical programs abroad</b> is because they offer quality education at very affordable prices without the hassles of entrance exams. The added advantage of the recognition by international bodies such as <b>Medical Council of India (MCI), UNESCO, and WHO</b>, allows the students to practice all over the world after passing out from these universities. The availability of the world class technology to educate and the superior infrastructure at these universities ensures that the students get the best out of these programs through <b>best MBBS abroad consultants in Delhi in 2022</b>.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <WhyChoose />
        <section className="mission_new_sec comman_tb_padding">
          <div className="container">
            <div className="intro_page_area page_abt_con mt-5">
              {/* <h3 class="sub_heading title_ribban txt_color_white">Introduction</h3> */}
              <div className="row intro_row align-items-center">
                <div className="col-lg-6 wow fadeInDown">
                  <div className="img_radius">
                    <img src="images/mission_img.jpg" alt="cheapest mbbs in abroad" title="cheapest mbbs in abroad" />
                  </div>
                </div>
                <div className="col-lg-6 wow fadeInUp">
                  <h4 className="sub_heading4">Our Mission &amp; Value</h4>
                  <h3 className="sub_heading title_ribban">Mission</h3>
                  <p>Doctorbano has been established with the mission to provide guidance to maximum number of students from India to realize their dream gaining a medical degree that is of international standards. We approach each <b>direct admission in MBBS</b> with utmost sincerity to ensure long term satisfaction for the students as well as their families.</p>
                </div>
              </div>
              <div className="row intro_row align-items-center">
                <div className="col-lg-6 wow fadeInDown">
                  <div className="img_radius">
                    <img src="images/vision_img.jpg" alt="cheapest mbbs in abroad 2022" title="cheapest mbbs in abroad 2022" />
                  </div>
                </div>
                <div className="col-lg-6 wow fadeInUp">
                  <h4>Our Vision &amp; Value</h4>
                  <h3 className="sub_heading title_ribban">Vision</h3>
                  <p>Our vision, at Doctorbano, is to be the best in the field of providing assistance for studying medicine abroad not just at affordable cost but at impeccable quality with satisfaction through <b>best MBBS abroad consultants in Delhi in 2022</b>.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
        <section className="university_sec curve_bg_style bg_color_light_gray" id="why_essque">
          <div className="bg_overlay" />
          <div className="container">
            {/* <div class="row">
      <div class="col-lg-6 wow flipInX">
        <h3 class="sub_heading title_ribban">Placement in Universities</h3>
      </div>
    </div> */}
            <div className="comman_top-">
              <div className="row">
                <div className="col-lg-8 top_uni_left wow fadeInUp">
                  <div>
                    <h3 className="sub_heading title_ribban">Why Doctorbano.com?</h3>
                    <ul className="list_styled why_ess_list">
                      <li>With Doctorbano.com, an Essqube Enterprises LLP initiative, there are no hidden costs. All the transactions are done electronically with the fees being directly paid to the universities.</li>
                      <li>The role of Doctorbano does not end with the student gaining admission but continues for the entire tenure of the student’s stay.</li>
                      <li>Due to its strong reciprocal relationship with the universities, Doctorbano is authorized to process all the paperwork,&nbsp; and <a href="https://www.mciindia.org/ActivitiWebClient/rulesnregulations/eligibilityCertificateRegulations2002">NMC eligibility</a>.</li>
                      <li>Trained personnel that help students and their family in the end-to-end <b>direct admission in MBBS</b> process</li>
                      <li>Can help parents also visit the either before the admission or after their children have sought admission in the university of their choice</li>
                      <li>Doctorbano being the <b>best MBBS abroad consultants in Delhi in 2022</b> also helps with the travel assistance such as suggesting the most affordable airlines, hotel bookings, student accommodations in the country of admission, etc.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 top_uni_right wow fadeInRight">
                  <div className="top_uni_img">
                    <img src="images/uni_student_img.png" alt="cheapest mbbs in abroad 2022" title="cheapest mbbs in abroad 2022" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default About