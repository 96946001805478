import React from 'react'
import HomeBanner from './HomeBanner'
import WhyChoose from './WhyChoose'
import Testimonial from './Testimonial'
import Countrylist from './Countrylist'
import UniversityLogo from './UniversityLogo'

const Home = () => {
  return (
    <>
      <HomeBanner />
      <section className="about_home_sec">
        <div className="container">
          <div className="about_home_area">
            <div className="row">
              <div className="col-lg-6 col-md-12 about_image_main wow fadeInUp">
                <div className="home_abt_img ">
                  <div className="img_radius">
                    <img src="images/ab_home_img.jpg" alt="Study MBBS Abroad 2022" title="Study MBBS Abroad 2022" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 about_content_main d-flex align-items-center wow fadeInDown md_res_mar_top_30">
                <div className="about-content">
                  {/* <h3>WHO AM I</h3> */}
                  <h3 className="sub_heading title_ribban">Best Education Consultants in Delhi, 2022</h3>
                  <p>Doctorbano.com, an Essqube Enterprises LLP initiative, established on March 9, 2017, has stepped into this space to help students to Study MBBS Abroad and choose the best medical universities around the globe. The dream of pursuing <b>MBBS in Russia, MBBS in Kazakhstan, MBBS in Georgia</b> and many countries that has an international flavour and exposure. </p>
                  <p>Doctorbano has developed reciprocal relationships with some of the best medical universities around the world.</p>
                  <a className="ab_more comman_btn" href="https://www.doctorbano.com/about.php">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <WhyChoose />
      <Testimonial />
      <Countrylist />
      {/* counter_sec */}
      <section className="counter_sec curve_bg_style bg_color_orange">
        <div className="bg_overlay" />
        <div className="container counter_container">
          <div className="counter_man_img wow fadeInRight">
            <img src="images/counter_people.png" alt="" />
          </div>
          <div className="row">
            <div className="col-lg-6 wow flipInX">
              <h3 className="sub_heading title_ribban txt_color_white">Trained and Professional Staffs at Best Education Consultant in Delhi</h3>
              <p className>Doctorbano team believes that dream to don a white coat should not be shattered by insufficient knowledge or financial reasons.</p>
            </div>
          </div>
          <div className="comman_top">
            <div className="row" id="counter">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 counter_box_comman wow fadeInLeft">
                <div className="counter_box">
                  <div className="counter_icon"><i className="fa fa-shield" aria-hidden="true" />
                  </div>
                  <div className="counter_txt">
                    <h2><span className="counter-value" data-count={12}>0</span> <span className="c_more">+</span></h2>
                    <h3>Years Experience</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 counter_box_comman wow fadeInUp">
                <div className="counter_box">
                  <div className="counter_icon"><i className="fa fa-fighter-jet" aria-hidden="true" /></div>
                  <div className="counter_txt">
                    <h2><span className="counter-value" data-count={25}>0</span> <span className="c_more">+</span></h2>
                    <h3>Countries</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 counter_box_comman wow fadeInDown">
                <div className="counter_box">
                  <div className="counter_icon"><i className="fa fa-globe" aria-hidden="true" /></div>
                  <div className="counter_txt">
                    <h2><span className="counter-value" data-count={69}>0</span> <span className="c_more">+</span></h2>
                    <h3>Professionals</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 counter_box_comman wow fadeInDown">
                <div className="counter_box">
                  <div className="counter_icon"><i className="fa fa-smile-o" aria-hidden="true" /></div>
                  <div className="counter_txt">
                    <h2><span className="counter-value" data-count={1031}>0</span> <span className="c_more">+</span></h2>
                    <h3>Happy Student</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* blog_home_sec */}
      <section className="blog_sec_home">
        <div className="container">
          <div className="heading_box text-center title_ribban wow flipInX">
            <h2>Newest Post</h2>
            <h4>Recently Updateds</h4>
          </div>
          <div className="blog_home_area">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 blog_box_main d-flex wow fadeInUp">
                <div className="blog_box_h_inner">
                  <div className="blog_img_box upcomimg_events_box_inner">
                    <div className="upcomimg_events_img_box"> <img src="images/top-mbbs-overseas-educational-consultant-in-delhi-2022.jpg" alt="" title="top mbbs overseas educational consultant in delhi 2022" /> </div>
                  </div>
                  <div className="blog_txt_area"> 
                    <h6>June 15, 2022</h6>
                    <h4><a href="" className="blog_h_head">Best MBBS Abroad Consultants in Delhi 2022</a></h4>
                    <p>Looking for the cheapest MBBS abroad 2022? Get hold of the cheapest MBBS abroad with the help of us, the best MBBS abroad consultants in Delhi.</p>
                    <a href="" className="blog_btn">Read More</a> </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex blog_box_main wow fadeInDown">
                <div className="blog_box_h_inner">
                  <div className="blog_img_box upcomimg_events_box_inner">
                    <div className="upcomimg_events_img_box"> <img src="images/top-mbbs-abroad-consultancy-in-delhi-2022.png" alt="top mbbs abroad consultancy in delhi 2022" title="top mbbs abroad consultancy in delhi 2022" /> </div>
                  </div>
                  <div className="blog_txt_area"> 
                    <h6>June 25, 2022</h6>
                    <h4><a href="" className="blog_h_head">Why choose Doctorbano as MBBS abroad consultancy services in Delhi</a></h4>
                    <p>Click to know the best MBBS abroad consultants in Delhi as well as the cheapest MBBS abroad consultants in Delhi in 2022</p>
                    <a href="" className="blog_btn">Read More</a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UniversityLogo />
    </>
  )
}

export default Home