import React from "react";
import UniversityLogo from "./UniversityLogo";
const Admission = () =>
{
    return(
        <>
            <section className="banner_new_sec country_banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12 ban_content_main d-flex align-items-center wow fadeInLeft">
                <div className="ban_content">
                  <h1 className="wow fadeInUp"><span>Cheapest Abroad Consultants</span></h1>
                  <h4 className="wow fadeInDown">MBBS consultancy services in Delhi</h4>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 ban_img_main wow fadeInLeft">
                <div className="ban_img_bx">
                  <img src="images/mbbs-admission-abroad-consultancy-services-in-delhi.png" alt="Cheapest Medical Colleges Abroad Consultants" title="Cheapest Medical Colleges Abroad Consultants" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* country_abt_sec */}
        <section className="about_home_sec contry_page_about">
          <div className="container">
            <div className="about_home_area">
              <div className="row">
                <div className="col-lg-6 col-md-12 about_image_main md_res_mar_top_30 wow fadeInUp">
                  <div className="home_abt_img ">
                    <div className="img_radius">
                      <img src="images/abroad-medical-college-consultancy-in-noida.png" alt="abroad medical college consultancy in noida" title="abroad medical college consultancy in noida" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 about_content_main d-flex align-items-center wow fadeInDown md_res_mar_top_30">
                  <div className="about-content">
                    {/* <h3>WHO AM I</h3> */}
                    <h2 className="sub_heading title_ribban">Cheapest Medical Colleges Abroad Consultants in 2022</h2>
                    <p>For those aspiring students who wish to <b>study MBBS abroad</b>, we, <b>doctorbano cheapest medical colleges abroad consultants in 2022</b> organise everything from start to finish and assist them in developing a career plan.</p>
                    {/* <a class="ab_more comman_btn" href="">Read More</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* other_feature_sec */}
        {/*?php include ("include/other_feature.php") ?*/}
        {/* country_accordian_sec */}
        <section className="country_accordian_sec curve_bg_style">
          <div className="bg_overlay" />
          <div className="container">
            <div className="con_accordian_style">
              <div className="accordian-widget">
                <div id="accordion_digi">
                  <div className="card">
                    <div className="card-header">
                      <a className="collapsed card-link" data-toggle="collapse" href="#faq_digi1">
                        Cheapest Medical Colleges Abroad Consultants - Admission Process  
                      </a>
                    </div>
                    <div id="faq_digi1" className="collapse" data-parent="#accordion_digi">
                      <div className="card-body">
                        <p>The trained and professional staffs at <a href="about.php">Doctorbano</a> have acquired thorough knowledge and expertise about admission procedures in <b>MBBS in Russia, MBBS in Armenia, and MBBS in Kyrgyzstan, MBBS in Kazakhstan, MBBS in Bangladesh</b>. These destinations are popular because the admission processes to the medical institutions here have been simplified to accommodate students from across the world.</p><p>Though the procedure is not complicated, it does require some good knowledge of admission and immigration policies of a country. Essqube team ensures that it stays apprised of any changes in these policies and help the students accordingly. The basic admission procedure is as follows:</p>
                        <ul className="list_styled">
                          <li>Send the documents to the chosen university</li>
                          <li>Apply for the offer letter – Doctorbano’s team is trained in preparing a convincing write up which can help students get the admission offer letter easily</li>
                          <li>Receive the offer letter</li>
                          <li>Initiate fees payment to the university</li>
                          <li>Order is sent to the concerned government department</li>
                          <li>Book the tickets – Doctorbano’s team can help you book the cheapest flight tickets</li>
                          <li>Accommodation – Doctorbano team can also help you find the right accommodation for you closer to the university</li>
                        </ul>
                        <p>Doctorbano team believes that dream to don a white coat should not be shattered by insufficient knowledge or financial reasons. The team at Essqube go out of their way to help the students aspiring to gain <b>cheapest medical colleges abroad</b> medical degree from countries like <a href="https://en.wikipedia.org/wiki/Russia">Russia</a>, Kazakhstan, Georgia, Armenia, Bangladesh, China, Ukraine.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <a className="collapsed card-link" data-toggle="collapse" href="#faq_digi2">
                        MBBS Consultancy Services - Eligibility Criteria
                      </a>
                    </div>
                    <div id="faq_digi2" className="collapse" data-parent="#accordion_digi">
                      <div className="card-body">
                        <p>Doctorbano team is well equipped and trained to guide you at the every step of your admission process.</p><p>The following is the eligibility criteria:</p>
                        <ul className="list_styled">
                          <li>Very flexible eligibility criteria, which is the USP of these programs.</li>
                          <li>Age bar is not less than 17 years.</li>
                          <li>Minimum 12<sup>th</sup> pass required for the bachelor programs (6 years) with at least 50% marks in physics, chemistry and biology.</li>
                          <li>Availability of original passing certificate with marks for verification.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <a className="collapsed card-link" data-toggle="collapse" href="#faq_digi3">
                        Documentation process 
                      </a>
                    </div>
                    <div id="faq_digi3" className="collapse" data-parent="#accordion_digi">
                      <div className="card-body">
                        <p>Doctorbano provides end-to-end solution from visa documentation to admission documentation. These processes can be time consuming, confusing, and not to mention, expensive if dealt with on their own. Doctorbano’s trained personnel have a clear understanding of the documentation requirements from the application stage to the visa stage as it is known for <b>mbbs admission abroad consultancy services in delhi</b>. This results in saving time as well as money for the students who are looking for opportunities to study abroad at a nominal cost.</p>
                        <p>The documentation process required for admission includes but not limited to:</p>
                        <ol>
                          <li>Copy of the complete passport</li>
                          <li>Final 12<sup>th</sup> standard mark sheet</li>
                          <li>Confirmed air ticket</li>
                          <li>Letter providing accommodation details</li>
                          <li>Insurance</li>
                          <li>Confirmed admission letter</li>
                          <li>Birth certificate for age criteria</li>
                          <li>Letter from Ministry</li>
                          <li>Bank statement to indicate the financial stability</li>
                          <li>Passport size photographs (5 nos)</li>
                          <li>Medical fitness clearance</li>
                          <li>Fees deposit slip</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <a className="collapsed card-link" data-toggle="collapse" href="#faq_digi4">
                        MBBS Admission Abroad Consultancy Services in Delhi
                      </a>
                    </div>
                    <div id="faq_digi4" className="collapse" data-parent="#accordion_digi">
                      <div className="card-body">
                        <p>We, at Doctorbano, provide <b>cheapest medical colleges abroad consultants in 2022</b> and end-to-end help to any medical degree aspirants who are seeking admissions in a medical college in Russia. Right from the moment a student and his family approach us for information to the moment the student acquires the admission, we hand-hold through every step of the process. Currently, at Doctorbano, we are providing assistance to the students with admission to medical programs in Ukraine. Very soon, we will be expanding our expertise to the admission processes in US, Canada, and other countries as well.</p>
                        <p>We provide following personalized services for students:</p>
                        <ul className="list_styled">
                          <li>Preliminary evaluation of all the required documentation to apply for a university</li>
                          <li>Help with all required attestations, translations, and authorizations required for application</li>
                          <li>Advice on financial aspects such as securing student loans from banks</li>
                          <li>Obtain official authority letters from the Universities</li>
                          <li>Relevant documentation from the education ministry of the chosen country</li>
                          <li>Obtain admission and letter and invitation letter from the chosen university</li>
                          <li>Deposit of the course fee with the chosen university</li>
                          <li>Airport pickup once the student arrives</li>
                          <li>Immigration clearance assistance</li>
                          <li>Arrange accommodation within university premises</li>
                          <li>Help open bank accounts and mobile connections in the chosen location</li>
                          <li>Provide orientation programs to make the students aware of the local environment and culture</li>
                          <li>Guidance with processes to obtain licenses to practice</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <a className="collapsed card-link" data-toggle="collapse" href="#faq_digi5">
                        Advantages - MBBS abroad for indian students consultants in Delhi
                      </a>
                    </div>
                    <div id="faq_digi5" className="collapse" data-parent="#accordion_digi">
                      <div className="card-body">
                        <p>Russia, Kazakhstan, Georgia, Armenia, Kyrgyzstan, China, and Ukraine are few of the top destinations today for students across the world to pursue medical education. Doctrobano a <b>cheapest medical colleges abroad consultants in 2022</b> gets your a right medical program for you.<br /> 
                          The advantages of studying MBBS in these destinations are:</p>
                        <ul>
                          <li>No IELTS or TOEFL clearance is required</li>
                          <li>No donation required and low course fees</li>
                          <li>English is the primary language of instruction, hence the international students do not face any language barriers</li>
                          <li>Up-to-date and most advanced teaching techniques with world-class amenities that ensures that students from these universities are at par in knowledge with universities across the world</li>
                          <li>WHO (World Health Organization), MCI, and UNESCO recognized universities.</li>
                          <li>Plenty of options for internship</li>
                          <li>Truly international exposure as students from across the world come here to pursue education</li>
                          <li>Indian culture and cuisine is well recognized here</li>
                          <li>Low cost of living</li>
                          <li>Secure, safe and culturally inclusive campus environment</li>
                          <li>There is no upper age limit.</li>
                          <li>Hospital training starts from first year and the third year sees the beginning of the PR</li>
                          <li>For doing PG, one can move to other countries in the EU and if one can learn the native language, the career prospects become much better.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* learner_says_sec */}
        <section className="learner_says_sec">
          <div className="container">
            <div className="heading_box text-center title_ribban">
              <h2>Learner Says</h2>
              <h4>Cheapest Medical Colleges Abroad Consultants in 2022</h4>
            </div>
            <div className="comman_top">
              <div className="learner_area">
                <div className="row">
                  <div className="col-lg-4 learner_box">
                    <div className="learner_box_inner">
                      <div className="learner_top">
                        <div className="learner_img">
                          <img src="images/testi_img1.jpg" alt="" />
                        </div>
                        <div className="learner_name">
                          <h3>Aman Fatima</h3>
                          {/* <h5>Developer</h5> */}
                        </div>
                      </div>
                      <div className="learner_bottom">
                        <p>Study MBBS aborad was like impossible to us, I and my father were just doubtful but the best guidance by doctorbano fulfilled our dream.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 learner_box">
                    <div className="learner_box_inner">
                      <div className="learner_top">
                        <div className="learner_img">
                          <img src="images/testi_img2.jpg" alt="" />
                        </div>
                        <div className="learner_name">
                          <h3>Daksh Seth</h3>
                          {/* <h5>Developer</h5> */}
                        </div>
                      </div>
                      <div className="learner_bottom">
                        <p>My mentor Sandeep Sharma from Doctorbano.com has guided me for medical admissions in Russia, I must say they are Best Education Consultants in Delhi</p>
                      </div>
                    </div>
                  </div>  
                  <div className="col-lg-4 learner_box">
                    <div className="learner_box_inner">
                      <div className="learner_top">
                        <div className="learner_img">
                          <img src="images/testi_img3.jpg" alt="" />
                        </div>
                        <div className="learner_name">
                          <h3>Abhimanyu Khasa</h3>
                          {/* <h5>Developer</h5> */}
                        </div>
                      </div>
                      <div className="learner_bottom">
                        <p>It was very difficult to study mbbs in Russia, and to handle the paper work, from invitation letter to the student visa, Doctorbano best education consultant in Delhi has arranged everything for us.</p>
                      </div>
                    </div>
                  </div>    
                </div>
              </div>
            </div>
          </div>
        </section>

        <UniversityLogo />
        </>
    )
}
export default Admission