import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Universities = () =>{

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          480: {
            items: 1,
          },
          768: {
            items: 1,
          },
          992: {
            items: 3,
            nav: false,
          }
        },
      };

      const options2 = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          480: {
            items: 1,
          },
          768: {
            items: 1,
          },
          992: {
            items: 3,
            nav: false,
          }
        },
      };


    return(
        <>
        <section className="banner_new_sec country_banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 ban_content_main d-flex align-items-center wow fadeInLeft">
                <div className="ban_content">
                  <h1 className="wow fadeInUp"><span>Choose Best Medical Universities Abroad</span></h1>
                  <h4 className="wow fadeInDown">Cheapest MBBS in abroad 2022</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ban_img_main wow fadeInLeft">
                <div className="ban_img_bx">
                  <img src="images/study-mbbs-abroad-consultants-in-delhi.png" alt="Choose Best Medical Universities Abroad Consultants in delhi" title="Choose Best Medical Universities Abroad Consultants in delhi" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
        <section className="about_contact_sec">
          <div className="container">
            <div className="about_home_area">
              <div className="row">
                <div className="col-lg-6 col-md-12 about_image_main md_res_mar_top_30 wow fadeInUp">
                  <div className="home_abt_img ">
                    <div className="img_radius">
                      <img src="images/mbbs-abroad-consultancy-in-punjab-2022.png" alt="mbbs-abroad-consultancy-in-punjab-2022" title="mbbs-abroad-consultancy-in-punjab-2022" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 about_content_main d-flex align-items-center wow fadeInDown md_res_mar_top_30">
                  <div className="about-content">
                    {/* <h3>WHO AM I</h3> */}
                    <h2 className="sub_heading title_ribban">Choose Best Medical Universities Abroad Consultants 2022</h2>
                    <p>Many students are perplexed about the score required for admission, and many students have financial difficulties. As a result, our bright and educated youth have begun to investigate by comparing the <b>cheapest MBBS in abroad 2022</b>.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 1. russia logo */}
        <section className="university_page_sec curve_bg_style bg_color_orange">
          <div className="bg_overlay" />
          <div className="container">
            <div className="heading_box text-center title_ribban">
              <h2 className="txt_color_white">Choose Best Medical Universities Abroad</h2>
              <h3 className="txt_color_white">Cheapest MBBS Colleges in Russia</h3>
            </div>
            <div className="comman_top">
              <div className="uni_page_logo_area">
              <OwlCarousel {...options} items={3}
                    className="owl-theme"
                    loop
                    nav
                    margin={8} >
                    <div className="item">
                        <div className="uni_big_logo_box">
                        <div className="uni_big_logo_inner">
                            <div className="uni_big_logo">
                            <img src="images/rus_uni_logo1.jpg" alt="Choose Best Medical Universities Abroad Consultants 2022" title="Choose Best Medical Universities Abroad Consultants 2022" />
                            <div className="logo_over">
                                <h4>Altai State Medical University</h4>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="uni_big_logo_box">
                        <div className="uni_big_logo_inner">
                            <div className="uni_big_logo">
                            <img src="images/rus_uni_logo2.jpg" alt="Choose Best Medical Universities Abroad Consultants 2022" title="Choose Best Medical Universities Abroad Consultants 2022" />
                            <div className="logo_over">
                                <h4>Altai State Medical University</h4>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="uni_big_logo_box">
                        <div className="uni_big_logo_inner">
                            <div className="uni_big_logo">
                            <img src="images/rus_uni_logo3.jpg" alt="Choose Best Medical Universities Abroad Consultants 2022" title="Choose Best Medical Universities Abroad Consultants 2022" />
                            <div className="logo_over">
                                <h4>Altai State Medical University</h4>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="uni_big_logo_box">
                        <div className="uni_big_logo_inner">
                            <div className="uni_big_logo">
                            <img src="images/rus_uni_logo4.jpg" alt="Choose Best Medical Universities Abroad Consultants 2022" title="Choose Best Medical Universities Abroad Consultants 2022" />
                            <div className="logo_over">
                                <h4>Altai State Medical University</h4>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        {/* 2. Kazakhstan logo */}
      <section className="uni_logo_white_sec">
        <div className="container">
          <div className="heading_box text-center ">
            <h2>Cheapest MBBS Colleges in Kazakhstan</h2>
          </div>
          <div className="comman_top">
            <div className="uni_page_logo_area">
            <OwlCarousel {...options2} items={3}
                    className="owl-theme"
                    loop
                    nav
                    margin={8} >
                    <div className="item">
                  <div className="uni_big_logo_box">
                    <div className="uni_big_logo_inner">
                      <div className="uni_big_logo">
                        <img src="images/kaz_uni_logo1.jpg" alt="Choose Best Medical Universities Abroad Consultants 2022" title="Choose Best Medical Universities Abroad Consultants 2022" />
                        <div className="logo_over">
                          <h4>Al-farabi Kazakh National University</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="uni_big_logo_box">
                    <div className="uni_big_logo_inner">
                      <div className="uni_big_logo">
                        <img src="images/kaz_uni_logo2.jpg" alt="Choose Best Medical Universities Abroad Consultants 2022" title="Choose Best Medical Universities Abroad Consultants 2022" />
                        <div className="logo_over">
                          <h4>Astana Medical University</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="uni_big_logo_box">
                    <div className="uni_big_logo_inner">
                      <div className="uni_big_logo">
                        <img src="images/kaz_uni_logo3.jpg" alt="Choose Best Medical Universities Abroad Consultants 2022" title="Choose Best Medical Universities Abroad Consultants 2022" />
                        <div className="logo_over">
                          <h4>International Medical School Kazakhstan</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="uni_big_logo_box">
                    <div className="uni_big_logo_inner">
                      <div className="uni_big_logo">
                        <img src="images/kaz_uni_logo4.jpg" alt="Choose Best Medical Universities Abroad Consultants 2022" title="Choose Best Medical Universities Abroad Consultants 2022" />
                        <div className="logo_over">
                          <h4>KAZAKH NATIONAL MEDICAL UNIVERSITY</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
        </>
    )
}

export default Universities