import React from "react"
const WhyChoose = () =>{
    return(
        <>
            <section className="site_feature_sec curve_bg_style">
                <div className="bg_overlay" />
                <div className="container">
                <div className="heading_box text-center title_ribban">
                    <h2 className="txt_color_white">Why Doctorbano.com</h2>
                    <h4 className="txt_color_white">Best Education Consultant in Delhi 2022</h4>
                </div>
                <div className="comman_top">
                    <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 site_feat_bx wow fadeInLeft">
                        <div className="site_feat">
                        <div className="site_f_icon">
                            <i className="fa fa-trophy" aria-hidden="true" />
                        </div>
                        <h3>Study MBBS in Abroad</h3>
                        <p>Your chance to be a trending expert in Medical and make a successful career after completion of our courses.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 site_feat_bx wow fadeInUp">
                        <div className="site_feat">
                        <div className="site_f_icon">
                            <i className="fa fa-question" aria-hidden="true" />
                        </div>
                        <h3>Cheapest MBBS Abroad</h3>
                        <p>Doctorbano.com is one of the upcoming consultancy, with a vision of providing excellent support.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 site_feat_bx wow fadeInRight">
                        <div className="site_feat">
                        <div className="site_f_icon">
                            <i className="fa fa-trophy" aria-hidden="true" />
                        </div>
                        <h3>MBBS Consultants 2022</h3>
                        <p>Get reliable consultation for professional education by our team of certified professionals.</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}
export default WhyChoose