import React from "react";

const UniversityLogo = ()=>{
    return(
        <>
            <section className="university_sec curve_bg_style bg_color_light_gray">
        <div className="bg_overlay" />
        <div className="container">
          <div className="row">
            <div className="col-lg-7 wow flipInX">
              <h3 className="sub_heading title_ribban">Choose Best MBBS Universities Abroad</h3>
            </div>
          </div>
          <div className="comman_top">
            <div className="row">
              <div className="col-lg-8 top_uni_left">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInLeft">
                    <div className="uni_logo">
                      <img src="images/uni_logo1.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInUp">
                    <div className="uni_logo">
                      <img src="images/uni_logo2.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInRight">
                    <div className="uni_logo">
                      <img src="images/uni_logo3.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInLeft">
                    <div className="uni_logo">
                      <img src="images/uni_logo4.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInDown">
                    <div className="uni_logo">
                      <img src="images/uni_logo5.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInRight">
                    <div className="uni_logo">
                      <img src="images/uni_logo6.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInLeft">
                    <div className="uni_logo">
                      <img src="images/uni_logo7.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInUp">
                    <div className="uni_logo">
                      <img src="images/uni_logo8.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6 uni_logo_box wow fadeInRight">
                    <div className="uni_logo">
                      <img src="images/uni_logo9.png" alt="Study MBBS in Russia 2022" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 top_uni_right wow fadeInRight">
                <div className="top_uni_img">
                  <img src="images/uni_student_img.png" alt="Study MBBS in Russia 2022" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    )
}
export default UniversityLogo