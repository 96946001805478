import React from 'react';
import {Link } from 'react-router-dom';

function Header() {
  return (
    <>
      <nav className="nav_sec" id="sticky-wrap">
        <div className="container">
          <div className="nav_inner">
            <div className="logo_area">
              <div className="logo_box"> <Link to="/"><img src="images/logo.png" alt="Doctorbano Delhi" title="Doctorbano Delhi" /></Link> </div>
            </div>
            <div className="nav_area">
              <div className="stellarnav">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/admission">Admission</Link></li>
                  <li><a href="">Countries</a>
                    <ul>
                      <li><a href="">MBBS in Russia</a></li>
                      <li><a href="">MBBS in Kazakhstan</a></li>
                      <li><a href="">MBBS in Georgia</a></li>
                      <li><a href="">MBBS in Bangladesh</a></li>
                      <li><a href="">MBBS in Armenia</a></li>
                      <li><a href="">MBBS in Kyrgyzstan</a></li>
                      <li><a href="">MBBS in China</a></li>
                      <li><a href="">MBBS in Philippines</a></li>
                      <li><a href="">MBBS in Ukraine</a></li>
                    </ul>
                  </li>
                  <li><Link to="/Universities">Universities</Link></li>
                  <li><a href="">Blogs</a></li>
                  <li className="desk_li_contact"><a href="">Contact Us</a></li>
                </ul>
              </div>
            </div>
            <div className="header_social_area">
              <a className="head_login_btn" href="">Contact Us</a>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header