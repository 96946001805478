import React from 'react'

const HomeBanner = () =>{
    return(
        <>
            <section className="banner_new_sec">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 ban_content_main d-flex align-items-center wow fadeInLeft">
                    <div className="ban_content">
                        <h1 className="wow fadeInUp"><span>Study MBBS </span>Abroad 2022</h1>
                        <h4 className="wow fadeInDown">Choose The Best MBBS Universities</h4>
                        <ul className="list_banner_btn">
                        <li className="wow fadeInUp"><a href=""><i className="fa fa-cog" aria-hidden="true" /> Apply Now</a></li>
                        <li className="b_btn2 wow fadeInUp"><a href="https://www.doctorbano.com/countries.php"><i className="fa fa-cog" aria-hidden="true" /> Know More</a></li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 ban_img_main wow fadeInLeft">
                    <div className="ban_img_bx">
                        <img src="images/ban_img1.png" alt="Study MBBS Abroad" title="Study MBBS Abroad" />
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )

}
export default HomeBanner