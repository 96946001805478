import React from "react";
const Countrylist = () =>{
    return(
        <>
            <section className="countries_sec">
        <div className="container">
          <div className="heading_box text-center title_ribban">
            <h2>Top Countries to Study MBBS in Abroad</h2>
            <h4>Get MBBS admission with top medical universities with us</h4>
          </div>
          <div className="comman_top">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 country_box_main d-flex wow fadeInDown">
                <div className="country_box_h_inner">
                  <div className="blog_img_box upcomimg_events_box_inner">
                    <div className="upcomimg_events_img_box"> <img src="images/study_russia.jpg" alt="Study MBBS in Russia 2022" title="Study MBBS in Russia 2022" /> </div>
                  </div>
                  <div className="country_txt_area"> 
                    <h4>Study In Russia</h4>
                    <p>Since the sixteenth century, European countries have ranked America as the leading country in medical education.</p>
                    <a href="" className="study_btn comman_btn">Read More</a> </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 country_box_main d-flex wow fadeInDown">
                <div className="country_box_h_inner">
                  <div className="blog_img_box upcomimg_events_box_inner">
                    <div className="upcomimg_events_img_box"> <img src="images/study_azakhstan.jpg" alt="Study MBBS in Kazakhstan 2022" title="Study MBBS in Kazakhstan 2022" /> </div>
                  </div>
                  <div className="country_txt_area"> 
                    <h4>Study In Kazakhstan</h4>
                    <p>Every year more than 10,000 Indian students are admitted to the medical program in Kazakhstan.</p>
                    <a href="" className="study_btn comman_btn">Read More</a> </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 country_box_main d-flex wow fadeInRight">
                <div className="country_box_h_inner">
                  <div className="blog_img_box upcomimg_events_box_inner">
                    <div className="upcomimg_events_img_box"> <img src="images/study_georgia.jpg" alt="Study MBBS in Georgia 2022" title="Study MBBS in Georgia 2022" /> </div>
                  </div>
                  <div className="country_txt_area"> 
                    <h4>Study In Georgia</h4>
                    <p>Students also favor Georgia as the most popular overseas favuorable medical education destination.</p>
                    <a href="" className="study_btn comman_btn">Read More</a> </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 country_box_main d-flex wow fadeInRight">
                <div className="country_box_h_inner">
                  <div className="blog_img_box upcomimg_events_box_inner">
                    <div className="upcomimg_events_img_box"> <img src="images/study_bangladesh.jpg" alt="Study MBBS in Bangladesh 2022" title="Study MBBS in Bangladesh 2022" /> </div>
                  </div>
                  <div className="country_txt_area"> 
                    <h4>Study In Bangladesh</h4>
                    <p>Around 25 state-funded universities located within Bangladesh as well as another 50 privately-owned.</p>
                    <a href="" className="study_btn comman_btn">Read More</a> </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 country_box_main d-flex wow fadeInLeft">
                <div className="country_box_h_inner">
                  <div className="blog_img_box upcomimg_events_box_inner">
                    <div className="upcomimg_events_img_box"> <img src="images/study_armenia.jpg" alt="Study MBBS in Armenia 2022" title="Study MBBS in Armenia 2022" /> </div>
                  </div>
                  <div className="country_txt_area"> 
                    <h4>Study In Armenia</h4>
                    <p>Students who are considering a degree program in Medicine will likely be most attracted by the modern classrooms</p>
                    <a href="" className="study_btn comman_btn">Read More</a> </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 country_box_main d-flex wow fadeInDown">
                <div className="country_box_h_inner">
                  <div className="blog_img_box upcomimg_events_box_inner">
                    <div className="upcomimg_events_img_box"> <img src="images/study_Kyrgyzstan.jpg" alt="Study MBBS in Kyrgyzstan 2022" title="Study MBBS in Kyrgyzstan 2022" /> </div>
                  </div>
                  <div className="country_txt_area"> 
                    <h4>Study In Kyrgyzstan</h4>
                    <p>Kyrgyzstan’s NMC accredited medical universities with quality education have helped to set a milestone</p>
                    <a href="" className="study_btn comman_btn">Read More</a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    )
}
export default Countrylist